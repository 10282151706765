export const hero = {
  title: "The right payment devices and accessories to power your business",
  subTitle: "Payment devices and accessories ",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/payment-devices/demo",
  },
};

export const heroImage = {
  imageName: "products-payment-devices-hero.png",
};

export const secondaryHeroTwoCols = {
  title: "Peripheral support that matches your operations",
  subtext:
    "Our omnichannel commerce solutions include integrations with leading payment providers and multiple accessory options that fit with your existing workflow. With options for cash drawers, payment devices, barcode scanners, scales, customer-facing displays and more, there’s no need to overhaul your current processes.",
  image: {
    imageName: "products-payment-devices-secondary-hero-2.png",
    mobileImageName: "products-payment-devices-secondary-hero-2-mobile.png",
    title: "We’re dedicated to your success",
    subtext: "PAYMENT DEVICES AND ACCESSORIES",
  },
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/payment-devices/demo",
  },
};

export const productOverview = {
  subSections: [
    {
      title: "Sell better with reliable payment accessories",
      subtext:
        "We work with only the best hardware partners to help you streamline front- and back-of-house operations with integrated payment support, a variety of cash drawer sizes and manufacturer-recommended mounting brackets, customer-facing displays, rugged receipt printers, and peripheral support options for barcode scanners, scales, and more.",
      imageName: "products-payment-devices-product-1.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/payment-devices/demo",
      },
    },
  ],
};

export const productsWithLinks = {
  title: "Built on a true omnichannel ecosystem",
  products: [
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform the guest experience, provide valuable business insights, and deliver ROI to your enterprise.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/payment-devices/demo",
  },
};
